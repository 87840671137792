import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Verb } from '../models/Verb';
import { Typography, Grid, Fade } from '@material-ui/core';
import ExampleMap from './ExampleMap';
import VerbPanel from './VerbPanel';
import VerbTableContainer from './VerbTableContainer';
import { prependOnceListener } from 'process';
import DataService from '../services/DataService';

interface VerbDialogProps {
  verb: Verb;
  onClose: () => void;
}

function VerbDialog(props: VerbDialogProps) {
  const handleClose = () => {
    props.onClose();
  };

  function hasLinks(verb: Verb) {
    return verb.links1?.length > 0 || verb.links2?.length > 0;
  }

  return (
    <div className="noselect">
      <Dialog
        open
        fullScreen
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          <span className="noselect">{props.verb.value}</span>
        </DialogTitle>
        <DialogContent>
          {
            hasLinks(props.verb)
            ?
            <TabbedVerbsPanels verb={props.verb} />
            :
            <VerbPanel verb={props.verb} />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

interface TabbedVerbPanelsProps {
  verb: Verb;
}

function TabbedVerbsPanels(props: TabbedVerbPanelsProps) {
  const [tabId, setTabId] = useState(0);
  const [link1Verbs, setLink1Verbs] = useState<Verb[]>([]);
  const [link2Verbs, setLink2Verbs] = useState<Verb[]>([]);

  function handleChange(event: ChangeEvent<{}>, newValue: any) {
    setTabId(newValue);
  }

  useEffect(() => {
    for (const id of props.verb.links1) {
      DataService.getVerbAsync(id).then(verb => {
        setLink1Verbs(value => {
          const copy = [...value];
          copy.push(verb);
          return copy;
        });
      })
    }
    for (const id of props.verb.links2) {
      DataService.getVerbAsync(id).then(verb => {
        setLink2Verbs(value => {
          const copy = [...value];
          copy.push(verb);
          return copy;
        });
      })
    }
  }, []);


  return (
    <div>
    <Tabs
      value={tabId}
      onChange={handleChange}
    >
      <Tab label={props.verb.value} />
      {
      link1Verbs.map((verb, index) => <Tab label={`自他対応-${index+1}`} />)
      }
      {
      link2Verbs.map((verb, index) => <Tab label={`同義異格-${index+1}`} />)
      }
    </Tabs>
    <div style={{ position: 'relative' }}>
      <Fade in={tabId === 0} timeout={200}  style={{ position: 'absolute', top: '0' }}>
        <div>
        <VerbPanel verb={props.verb} />
        </div>
      </Fade>
    {
      link1Verbs.map((verb, index) => (
        <Fade in={tabId === (index + 1)} timeout={200}  style={{ position: 'absolute', top: '0' }}>
          <div>
          <VerbPanel verb={verb} />
          </div>
        </Fade>
      ))
    }
    {
      link2Verbs.map((verb, index) => (
        <Fade in={tabId === (index + 1 + link1Verbs.length)} timeout={200}  style={{ position: 'absolute', top: '0' }}>
          <div>
          <VerbPanel verb={verb} />
          </div>
        </Fade>
      ))
    }
    </div>
    </div>
  )
}

export default VerbDialog;
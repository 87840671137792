import axios from 'axios';
import React, { Component, Fragment, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';

// import '../styles/Login.css';

interface LoginProps {
  onLogin: (data: any) => void;
}

const Login = (props: LoginProps) => {
  const [authenticating, setAuthenticating] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const login = async () => {
    const url = 'https://auth.dondoushidon.com/api/authenticate'
    //const url = 'http://localhost:8999/api/authenticate';
    const body = `username=${username}&password=${password}`

    const { data }  = await axios.post(
      url,
      body,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );

    props.onLogin(data);
  }

  return (
    <Paper>
      <Box p={4}>
      <form className="login-form" onSubmit={ async e => await login() }>
        <Grid
          container
          direction="column"
          spacing={3}
        >
          <Grid item>
            <Typography variant="h4">
              Don動詞どん
            </Typography>
          </Grid>
          <Grid>
            <TextField
              variant="outlined"
              label="Username"
              value={username}
              onChange={ e => setUsername(e.target.value) }
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              type="password"
              label="Password"
              value={password}
              onChange={ e => setPassword(e.target.value) }
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={authenticating}
              onClick={ async e => await login() }
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </form>
      </Box>
    </Paper>
  )
}

export default Login;

import axios from 'axios';

import Config from '../config';

import VerbsResponse from '../models/VerbsResponse';
import { User } from '../models/User';
import constants from '../constants';
import { Verb } from '../models/Verb';
import { Language } from '../models/Language';
import { FullSentenceDefinition } from '../models/FullSentenceDefinition';
import { VerbLink } from '../models/VerbLink';
import { Example } from '../models/Example';
import Comment from '../models/Comment';
import TextOption from '../models/TextOption';
import { CandidateType } from '../models/CandidateType';
import { StageGroups } from '../models/StageGroups';
import { Suggestion } from '../models/Suggestion';
import { FinalCheckList } from '../models/FinalCheckList';
import UserProgress from '../models/UserProgress';
import UserHistory from '../models/UserHistory';
import { Message } from '../models/Message';

class DataService {
  public async addVerb(value: string) {
    const newVerb = { value };
    const { data } = await axios.post(`${Config.apiUrl}/api/addVerb`, newVerb);

    return data as number;
  }

  public async getStageProgress() {
    const { data } = await axios.get(`${Config.apiUrl}/api/stageprogress`);

    return data as number[];
  }

  public async getUserProgress() {
    const { data } = await axios.get(`${Config.apiUrl}/api/userprogress`);

    return data as UserProgress[];
  }

  public async getUserHistory() {
    const { data } = await axios.get<UserHistory[]>(`${Config.apiUrl}/api/userhistory`);

    return data;
  }

  public async getVerbs(
    page: number,
    numItemsPerPage: number,
    stage: number,
    search: string = '',
    userId: string = ''
  ): Promise<VerbsResponse> {
      let url = `${Config.apiUrl}/api/verbs/${page}/${numItemsPerPage}/${stage}`
      if (userId) {
        url += `/${userId}`;
      }
      if (search) {
        url += `?search=${search}`;
      }
      const res = await axios.get(url);

      return res.data as VerbsResponse;
  }

  public async getVerbAsync(id: string): Promise<Verb> {
      const { data } = await axios.get<Verb>(`${Config.apiUrl}/api/verbs/id/${id}`);

      return data;
  }

  public async updateVerb(verb: Verb, previousStage?: number): Promise<Verb> {
    if (verb.stage === 2
      && verb.jpAssigneeId != null && verb.jpAssigneeId !== constants.emptyUuid
      && verb.jpCheckerId != null && verb.jpCheckerId !== constants.emptyUuid) {
        verb.stage = 3;
    }
    if (verb.stage === 6
      && verb.enAssigneeId != null && verb.enAssigneeId !== constants.emptyUuid
      && verb.enAssigneeId != null && verb.enCheckerId !== constants.emptyUuid) {
        verb.stage = 7;
    }

    const { data } = await axios.post(`${Config.apiUrl}/api/updateVerb?previousStage=${previousStage}`, verb);

    return data as Verb;
  }

  public async getFinalFullSentenceDefinition(verbId: string, verbStage: number): Promise<FullSentenceDefinition> {
    const { data } = await axios.get(`${Config.apiUrl}/api/finalFsd/verbId/${verbId}/stages/${verbStage}`);
    const fsd = data as FullSentenceDefinition;
    console.debug(`Final FullSentenceDefinition: ${JSON.stringify(fsd)}`);

    return fsd;
  }

  public async getMembers(
    language: Language,
    includeManagers: boolean = true,
    includeNotSet: boolean = true
  ): Promise<User[]> {
    const token = localStorage.getItem('d3');
    const url = language === 1
      ? `${Config.apiUrl}/api/jpMembers?token=${token}`
      : `${Config.apiUrl}/api/enMembers?token=${token}`;
    const { data } = await axios.get<User[]>(url);
    const members = includeManagers
    ? data
    : data.filter(u => u.roles && !u.roles.includes('jp-manager') && !u.roles.includes('en-manager'));
    if (includeNotSet) {
      members.push({
          userId: constants.emptyUuid,
          displayName: '未設定',
          username: '',
          firstName: '',
          lastName: '',
          email: ''
      });
    }
    console.log(JSON.stringify(members));

    return members;
  }

  public async getManagers(language: Language): Promise<User[]> {
    const token = localStorage.getItem('d3');
    const url = language === 1
      ? `${Config.apiUrl}/api/jpManagers?token=${token}`
      : `${Config.apiUrl}/api/enManagers?token=${token}`;
    const { data } = await axios.get(url);
    const managers = data;
    managers.push({
        userId: constants.emptyUuid,
        displayName: '未設定',
        username: null
    });

    return managers;
  }

  public async getAdmins(): Promise<User[]> {
    const token = localStorage.getItem('d3');
    const url = `${Config.apiUrl}/api/admins?token=${token}`;
    const { data } = await axios.get<User[]>(url);

    return data;
  }

  public async getStageGroups() {
    const token = localStorage.getItem('d3');
    const { data } = await axios.get<User[]>(`${Config.apiUrl}/api/stageGroups?token=${token}`);
    const stageGroups = {
      stage5group: data.find(u => u.username === 'stage5group'),
      stage8group: data.find(u => u.username === 'stage8group'),
      stage9group: data.find(u => u.username === 'stage9group')
    } as StageGroups;

    return stageGroups;
  }

  public async getVerbLink(verbId: string): Promise<VerbLink> {
    const { data } = await axios.get(`${Config.apiUrl}/api/verbs?id=${verbId}`);
    const link = data as VerbLink;
    console.debug(`Final FullSentenceDefinition: ${JSON.stringify(link)}`);

    return link;
  }

  public async getSelectedExamples(
    verbId: string,
    stage: number,
    language: Language,
  ): Promise<Example[]> {
    const { data } = await axios.get<Example[]>(
      `${Config.apiUrl}/api/examples/${language}/verbId/${verbId}/stage/${stage}`
    );

    const examples = [] as Example[];
    for (let i = 0; i < 4; i++) {
      const example = data.find(e => e.localId === i);
      examples.push(
        example || {
          value: '',
          verbId,
          selected: false,
          localId: i,
          language,
          stage,
          status: 0
        } as Example
      );
    }
    return examples;
  }

  private sortExamplesByLocalId(examples: Example[]) {
    return examples.sort((a, b) => {
      if (a.localId < b.localId) {
        return -1;
      } else if (a.localId > b.localId) {
        return 1;
      } else {
        return 0;
      }
    })
  }

  public async getExamples<T>(
    verbId: string,
    stage: number,
    language: Language,
    localId: number
  ): Promise<T[]> {
    const { data } = await axios.get<T[]>(
      `${Config.apiUrl}/api/examples/${language}/verbId/${verbId}/localId/${localId}/stage/${stage}`
    );

    return data;
  }

  public async getFsds<T>(
    verbId: string,
    stage: number,
  ): Promise<T[]> {
    const { data } = await axios.get<T[]>(
      `${Config.apiUrl}/api/fsds/verbId/${verbId}/exactstage/${stage}`
    );

    return data;
  }

  public async getSelectedFsd(
    verbId: string,
    stage: number,
  ): Promise<FullSentenceDefinition> {
    const { data } = await axios.get<FullSentenceDefinition>(
      `${Config.apiUrl}/api/fsds/verbId/${verbId}/stage/${stage}?selectedonly=true`
    );

    return data;
  }

  public async getCandidates<T extends TextOption>(
    type: CandidateType,
    verbId: string,
    stage: number,
    language: Language,
    localId: number
  ): Promise<T[]> {
    if (type === 'fsd') {
      return await this.getFsds(verbId, stage);
    } else {
      return await this.getExamples(verbId, stage, language, localId!);
    }
  }

  public async getComments(linkId: string): Promise<Comment[]> {
    const { data } = await axios.get<Comment[]>(
      `${Config.apiUrl}/api/comments/${linkId}`
    );

    return data;
  }

  public async saveComment(comment: Comment): Promise<void> {
    await axios.post<Comment>(
      `${Config.apiUrl}/api/updateComment`,
      comment
    );
  }

  public async updateExample(value: Example): Promise<Example> {
    const { data } = await axios.post<Example>(
      `${Config.apiUrl}/api/updateExample`,
      value
    );

    return data;
  }

  public async updateFullSentenceDefinition(value: FullSentenceDefinition): Promise<FullSentenceDefinition> {
    const { data } = await axios.post<FullSentenceDefinition>(
      `${Config.apiUrl}/api/updateFullSentenceDefinition`,
      value
    );

    return data;
  }

  public async getSuggestions(verbId: string): Promise<Suggestion> {
    const { data } = await axios.get<Suggestion>(
      `${Config.apiUrl}/api/suggestions/verbId/${verbId}`
    );

    return data;
  }

  public async updateSuggestions(verbId: string, value: Suggestion): Promise<Suggestion> {
    const { data } = await axios.post<Suggestion>(
      `${Config.apiUrl}/api/suggestions/verbId/${verbId}`,
      value
    );

    return data;
  }

  public async getChecklist(verbId: string): Promise<FinalCheckList> {
    const { data } = await axios.get<FinalCheckList>(
      `${Config.apiUrl}/api/finalCheckList/verbId/${verbId}`
    );

    return data;
  }

  public async updateChecklist(verbId: string, value: FinalCheckList): Promise<void> {
    await axios.post<FinalCheckList>(
      `${Config.apiUrl}/api/finalCheckList/verbId/${verbId}`,
      value
    );
  }

  public async getSelecteExamples(lang: Language, verbId: string, stage: number): Promise<Example[]> {
    const { data } = await axios.get<Example[]>(
      `${Config.apiUrl}/api/examples/${lang}/verbId/${verbId}/stage/${stage}`
    );

    return data;
  }

  public async duplicateVerb(
    verb: Verb,
    jpExamples: Example[],
    enExamples: Example[]
  ) {
    const { data } = await axios.post(`${Config.apiUrl}/api/cloneVerb`, verb);
    const clonedVerb = JSON.parse(JSON.stringify(data)) as Verb;

    for (let i = 0; i < 4; i++) {
      if (jpExamples[i]) {
        const clonedExample = JSON.parse(JSON.stringify(jpExamples[i]));
        clonedExample.id = null; // to create a new one
        clonedExample.verbId = clonedVerb.id;
        await axios.post(`${Config.apiUrl}/api/updateExample`, clonedExample);
      }
      if (enExamples[i]) {
        const clonedExample = JSON.parse(JSON.stringify(enExamples[i]));
        clonedExample.id = null; // to create a new one
        clonedExample.verbId = clonedVerb.id;
        await axios.post(`${Config.apiUrl}/api/updateExample`, clonedExample);
      }
    }
  }

  public async getMessagesAsync(verbId: string) {
    const { data } = await axios.get<Message[]>(`${Config.apiUrl}/api/messages/verbs/${verbId}`);

    return data;
  }

  public async resolveMessageAsync(id: string, username: string): Promise<Message[]> {
    const { data } = await axios.post<Message[]>(
      `${Config.apiUrl}/api/messages/${id}/users/${username}/resolve`
    );

    return data;
  }

  public async unresolveMessageAsync(id: string, username: string): Promise<Message[]> {
    const { data } = await axios.post<Message[]>(
      `${Config.apiUrl}/api/messages/${id}/unresolve`
    );

    return data;
  }

  public async deleteMessageAsync(id: string): Promise<Message[]> {
    const { data } = await axios.delete(
      `${Config.apiUrl}/api/messages/${id}`
    );

    return data
  }
}

export default new DataService();

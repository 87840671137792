import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import VerbTableContainer from './VerbTableContainer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface VerbViewerProps {
  onLogout: () => void;
}

const VerbViewer = (props: VerbViewerProps) => {
  const classes = useStyles();
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography
            className={classes.title}
            variant="h6"
          >
          Don動詞どん
          </Typography>
          <Button
            color="inherit"
            onClick={props.onLogout}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <VerbTableContainer />
    </>
  )
}

export default VerbViewer;

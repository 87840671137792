import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, Grid } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import ExampleMap from './ExampleMap';
import { Verb } from '../models/Verb';

import '../styles/VerbPanel.css';

interface VerbPanelProps {
  verb: Verb;
}

function VerbPanel(props: VerbPanelProps) {
  return (
    <>
    {
    props.verb.stage < 10
    ?
    <div className="under-construction">
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <WarningIcon fontSize="large" className="warning" />
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h6">
                UNDER CONSTRUCTION
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Coming Soon
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
    :
    <div className="noselect">
      <Grid container direction="column" spacing={2} className="noselect">
        <Grid item>
          <Typography variant="body1">
            {props.verb.structure}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {props.verb.fullSentenceDefinition}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {props.verb.noteForReferenceBook}
          </Typography>
        </Grid>
        <Grid item>
          <ExampleMap
            verb={props.verb}
          />
        </Grid>
      </Grid>
    </div>
    }
    </>
  );
}

export default VerbPanel;
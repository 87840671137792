import React, { useEffect, useState } from 'react';

import { Verb } from '../models/Verb';
import DataService from '../services/DataService';
import VerbTable from './VerbTable';
import { Box, Paper } from '@material-ui/core';
import SearchField from './SearchField';

interface VerbTableProps {
}

const VerbTableContainer = (props: VerbTableProps) => {
  const [verbs, setVerbs] = useState<Verb[]>([]);
  const [numVerbs, setNumVerbs] = useState(0);
  const [page, setPage] = useState(0);
  const [numItemsPerPage, setNumItemsPerPage] = useState(10);
  const [stage, setStage] = useState(10); // * Change the value to 10 on roll
  const [searchText, setSearchText] = useState('');

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
    getVerbs(searchText);
  }

  const getVerbs = async (search?: string) => {
    const res = await DataService.getVerbs(
      page,
      numItemsPerPage,
      stage,
      search
    );
    setVerbs(res.verbs);
    setNumVerbs(res.numVerbs);
  };

  useEffect(() => {
      getVerbs();
  }, []);

  return (
    <Box p={4}>
      <SearchField
        disabled={!searchText && verbs.length === 0}
        trigger="enter"
        onSearch={handleSearch}
      />
      <VerbTable verbs={verbs} />
    </Box>
  )
}

export default VerbTableContainer;

import React, { Component, Fragment, useState, useEffect } from 'react';
import { CandidateType } from '../models/CandidateType';
import { Verb } from '../models/Verb';
import DataService from '../services/DataService';
import { LinearProgress } from '@material-ui/core';

interface ExampleMapProps {
  verb: Verb;
}

interface ExampleMapState {
  examples: string[][];
}

const ExampleMap = (props: ExampleMapProps) => {
  const [examples, setExamples] = useState<string[][]>();

  useEffect(() => {
    getExamples();
  }, []);

  const getExamples = async ()  => {
    const { id, stage } = props.verb;
    const jpExamples = await DataService.getSelectedExamples(id, stage, 1);
    const enExamples = await DataService.getSelectedExamples(id, stage, 2);
    const examples = [];
    for (let i = 0; i < 4; i++) {
      const jp = jpExamples.find(e => e.localId === i);
      const en = enExamples.find(e => e.localId === i);
      const pair = [] as string[];
      pair.push(
        jp ? jp.value : '',
        en ? en.value : ''
      );
      examples.push(pair);
    }
    setExamples(examples);
  }

  return (
    <>
    {
      examples === undefined
      ?
      <div style={{ width: '100%' }}><LinearProgress /></div>
      :
      <table className="example-table">
        <tbody>
        {
          examples!.map((pair, index) =>
            <tr key={index}>
                <td>
                  <span dangerouslySetInnerHTML={{__html: pair[0] || 'No example selected'}}></span>
                </td>
                <td>
                  <span dangerouslySetInnerHTML={{__html: pair[1] || 'No example selected'}}></span>
                </td>
            </tr>
          )
        }
        </tbody>
      </table>
    }
    </>
  );
}

export default ExampleMap;

import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Verb } from '../models/Verb';
import { TableBody } from '@material-ui/core';
import VerbDialog from './VerbDialog';

interface VerbTableProps {
  verbs: Verb[];
}

const VerbTable = (props: VerbTableProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedVerb, setSelectedVerb] = useState<Verb | null>(null);

  const handleVerbClick = (verb: Verb) => {
    if (!showDialog) {
      setShowDialog(true);
      setSelectedVerb(verb);
    }
  }

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              Verb
            </TableCell>
            <TableCell>
              Structure
            </TableCell>
            <TableCell>
              Definition
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          props.verbs.map(verb => (
            <TableRow
              hover
              style={{ cursor: 'pointer' }}
              onClick={ () => handleVerbClick(verb) }
            >
              <TableCell>
                {verb.value}
              </TableCell>
              <TableCell>
                {verb.structure}
              </TableCell>
              <TableCell>
                {verb.fullSentenceDefinition}
              </TableCell>
            </TableRow>
          ))
        }
        </TableBody>
      </Table>
      {
        showDialog && selectedVerb
        &&
        <VerbDialog
          verb={selectedVerb}
          onClose={ () => setShowDialog(false) }
        />
      }
    </>
  )
}

export default VerbTable;

import React, { CSSProperties, useState, ChangeEvent, KeyboardEvent } from 'react';
import TextField from '@material-ui/core/TextField';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { InputAdornment, Theme } from '@material-ui/core';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';

type SearchTrigger = 'type' | 'enter';

interface SearchFieldProps {
  autoFocus?: boolean;
  disabled: boolean;
  style?: CSSProperties;
  trigger?: SearchTrigger;
  onSearch: (text: string) => void;

}

const SearchField = (props: SearchFieldProps) => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (!props.trigger || props.trigger === 'type') {
      props.onSearch(event.target.value);
    }
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (props.trigger === 'enter' && event.key === 'Enter') {
      event.preventDefault();
      props.onSearch(value);
    }
  }

  const handleClearText = () => {
    setValue('');
    props.onSearch('');
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      textField: {
        margin: theme.spacing(1)
      },
      clearButtonVisible: {
        visibility: 'visible'
      },
      clearButtonHidden: {
        visibility: 'hidden'
      }
    })
  );

  const classes = useStyles(props);
  return (
    <TextField
      variant="outlined"
      margin="dense"
      label="Search"
      value={value}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      style={props.style}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={
                value
                ?
                classes.clearButtonVisible
                :
                classes.clearButtonHidden
              }
              onClick={handleClearText}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
    />
  )
}

export default SearchField;

const Config = {
  dev: {
    apiUrl: 'http://localhost:5000',
    dlUrl: 'http://localhost:4000',
    env: 'dev'
  },
  staging: {
    apiUrl: 'https://staging-server.dondoushidon.com',
    dlUrl: 'https://staging-server2.dondoushidon.com',
    env: 'staging'
  },
  prod: {
    apiUrl: 'https://server.dondoushidon.com',
    dlUrl: 'https://server2.dondoushidon.com',
    env: 'prod'
  }
}

let _config = Config.dev;
switch (process.env.REACT_APP_STAGE) {
  case 'staging':
    _config = Config.staging;
    break;
  case 'prod':
    _config = Config.prod;
    break;
}

export default _config;


import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './Login';
import VerbViewer from './components/VerbViewer';

const TokenStorageKey = 'd3';
const UserStorageKey = 'd3-user';

function App() {
  const [token, setToken] = useState(localStorage.getItem(TokenStorageKey));
  const [username, setUsername] = useState(localStorage.getItem(UserStorageKey)
    ? JSON.parse(localStorage.getItem(UserStorageKey)!).username
    : ''
  );

  const onLogin = (data: any) => {
    if (data && data.token) {
      localStorage.setItem(TokenStorageKey, data.token);
      localStorage.setItem(UserStorageKey, JSON.stringify(data));
      setToken(data.token);
      setUsername(data.username);
    } else {
      localStorage.removeItem(TokenStorageKey);
      localStorage.removeItem(UserStorageKey);
      setToken('');
      setUsername('');
    }
  }

  const onLogout = () => {
    localStorage.removeItem(TokenStorageKey);
    localStorage.removeItem(UserStorageKey);
    setToken('');
    setUsername('');
  }

  return <>{
    token
    ?
    <div className="noselect">
      <VerbViewer onLogout={onLogout} />
    </div>
    :
    <div className="App">
      <Login onLogin={onLogin} />
    </div>
  }</>
}

export default App;
